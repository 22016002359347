@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'dark';
@import 'ui/styles/colors';
@import 'ui/styles/fonts';

.container {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  border: 1px solid $color-gray-400;
  padding: 0 6px;

  &.dark {
    @extend %dark;
  }

  > .list-wrapper {
    position: relative;
    border-top: 10px solid white;
    border-bottom: 10px solid white;
    border-radius: inherit;

    &.sub {
      > .item-wrapper {
        > .item {
          height: 52px;
          align-items: flex-start;
        }
      }
    }

    > .item-wrapper {
      position: relative;

      > .sub-menu {
        display: none;
        position: absolute;
        width: max-content;
        box-shadow: 0 6px 15px rgb(0 0 0 / 15%), 0 4px 6px rgb(0 0 0 / 5%);
        top: -5px;

        &.right {
          left: 100%;
        }

        &.left {
          right: 100%;
        }
      }

      &:hover {
        > .item:not(:disabled) {
          background: $color-gray-80;
          color: $color-gray-800;
        }

        > .sub-menu {
          min-width: calc(100% + 10px);
          display: flex;
        }
      }

      > .item {
        display: flex;
        align-items: center;
        padding: 0 8px;
        height: 32px;
        gap: 4px;

        @include font(b3);

        color: $color-gray-600;
        background: none;
        border-radius: 4px;
        border: none;
        outline: none;
        cursor: pointer;
        width: 100%;

        > .more-icon-wrap {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;

          svg {
            fill: $color-gray-600;
          }
        }

        > .label {
          margin: 6px 0 0 10px;
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;

          @include font(b3);

          color: $color-teal-600;
        }

        &:not(:disabled).selected {
          font-weight: 700;
          color: $color-gray-800;

          > .icon {
            fill: $color-gray-800;
          }
        }

        &:focus:not(.has-sub-menu),
        &:hover:not(:disabled) {
          background: $color-gray-80;
          color: $color-gray-800;

          > .icon {
            fill: $color-gray-800;
          }
        }

        &:disabled {
          cursor: not-allowed;
          color: $color-gray-400;

          > .icon {
            fill: $color-gray-400;
          }

          > .label {
            color: $color-gray-400;
          }

          > .name-wrap > .sub {
            color: $color-gray-400;

            & + .name {
              color: $color-gray-400;
            }
          }
        }

        > .checkbox {
          pointer-events: none;

          & + * {
            margin-left: 10px;
          }
        }

        > .icon {
          fill: $color-gray-600;

          & + * {
            margin-left: 4px;
          }
        }

        > .name-wrap {
          display: flex;
          flex-direction: column-reverse;
          text-align: left;

          > .sub {
            margin-top: 2px;
            color: $color-gray-600;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            & + .name {
              color: $color-gray-800;
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid rgba($color-gray-500, 0.8);
    }
  }
}
