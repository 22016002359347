@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
// Breakpoints
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1279px;

.contentsArea {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .contentsWrap {
    position: relative;
    width: 100%;
    height: 100%;

    .container {
      margin: auto;
      width: 100%;
      max-width: 1216px;

      &.full-width {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: #{$breakpoint-mobile}) {
  .contentsArea {
    .contentsWrap {
      .container {
        padding: 0 24px;

        &.full-width {
          padding: 0;
        }
      }
    }
  }
}

@media (min-width: #{$breakpoint-mobile + 1px}) and (max-width: #{$breakpoint-tablet}) {
  .contentsArea {
    .contentsWrap {
      .container {
        padding: 0 40px;

        &.full-width {
          padding: 0;
        }
      }
    }
  }
}
