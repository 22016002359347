@import "ui/styles/colors";

%dark {
  background: $color-gray-900;
  border: none;

  > .list-wrapper {
    border-top: 10px solid $color-gray-900;
    border-bottom: 10px solid $color-gray-900;

    > .item-wrapper {
      > .sub-menu {
        @extend %dark;
      }

      &:hover {
        > .item:not(:disabled) {
          background: $color-gray-700;
          color: $color-gray-300;
        }
      }

      > .item {
        color: $color-gray-300;

        > .icon {
          fill: $color-gray-300;
        }

        > .more-icon-wrap {
          svg {
            fill: $color-gray-300;
          }
        }

        > .label {
          color: $color-teal-600;
        }

        &:not(:disabled).selected {
          color: white !important;

          > .icon {
            fill: white !important;
          }
        }

        &:focus:not(.has-sub-menu),
        &:hover:not(:disabled) {
          background: $color-gray-700;
          color: $color-gray-300;

          > .icon {
            fill: $color-gray-300;
          }
        }

        &:disabled {
          color: $color-gray-700;

          > .icon {
            fill: $color-gray-700;
          }

          > .label {
            color: $color-gray-700;
          }

          > .name-wrap > .sub {
            color: $color-gray-700;

            & + .name {
              color: $color-gray-700;
            }
          }
        }

        > .name-wrap {
          > .sub {
            color: $color-gray-600;

            & + .name {
              color: $color-gray-300;
            }
          }
        }
      }
    }
  }
}