@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import 'ui/styles/colors';

.TABLET {
  &.container {
    padding: 24px 40px;
  }
}

.MOBILE {
  &.container {
    padding: 0 24px 24px 24px;
    height: 100%;

    .wrap {
      flex-direction: column;

      .info-wrap {
        width: 100%;
        height: 100%;
        margin-top: 48px;
      }

      .right-wrapper {
        align-items: flex-start;

        .footer-logo {
          right: 24px;
        }
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 188px;
  padding: 48px;
  border-top: 1px solid $color-gray-400;

  &.darkMode {
    background: $color-gray-900;
    border-top: none;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    width: 1216px;
    height: 100%;

    .info-wrap {
      flex-shrink: 0;
      width: 65%;
      min-height: 98px;
    }

    .right-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .footer-logo {
        position: absolute;
        display: block;
        width: 142px;
        height: 22px;
        bottom: 24px;
      }
    }
  }
}
