@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.modal-container {
  width: 480px;
}
.modal-body {
  height: 320px;
  overflow-y: scroll;
  padding: 24px;
  white-space: pre-line;

  .modal-body-title {
    margin-bottom: 12px;
  }
}
