@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.modal-container {
  max-width: 90vw;
}

.modal-body {
  height: 480px;
  padding: 24px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;

  .policy-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .policy-title {
      width: 100%;
    }

    .policy-dropdown {
      width: 240px;
    }
  }

  .policy-content {
    @include modal-scroll;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid $color-gray-400;
    overflow: auto;
  }
}
