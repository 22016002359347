@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
.linkArea {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
}

.link {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $color-gray-700;
  cursor: pointer;
  border: 1px solid transparent;
  min-height: 24px;
  min-width: 10px;
  max-width: 274px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.darkMode {
    color: $color-gray-600;
  }

  &.em {
    font-weight: 700;
  }

  &.highlight {
    color: $color-blue-600;
  }

  .link-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.divider {
  width: 1px;
  height: 12px;
  background: $color-gray-700;
  margin: 0 16px;
}
